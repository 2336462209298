import { css } from "lit";
import "../../../../../../legl-ui/lds-alert";
import { LeglAssistSummary } from "./summary.js";

// Superclass for CDD-specific summary components.
// This enables overriding styles and other functionality as needed.
// Without polluting the base component.
export class CddLeglAssistSummary extends LeglAssistSummary {
  static styles = [
    LeglAssistSummary.styles,
    css`
            .content h3:first-of-type {
                margin: 0 0 16px 0;
            }

            .content ul {
                font-size: 16px;
                padding-left: 30px;
                margin: 0;
            }

            .content p {
                white-space: break-spaces;
            }

            .content ul ~ p,
            .content ul ~ h4 {
                padding-left: 30px;
                margin-top: 8px;
                margin-bottom: 0;
            }

            .content h3:last-of-type ~ p,
            .content h3:last-of-type ~ h4 {
                padding-left: 0;
            }

            .content li h4 {
                margin-top: 16px;
                margin-bottom: 0;
            }
        `,
  ];
}

customElements.define("cdd-legl-assist-summary", CddLeglAssistSummary);
