import { css, html, nothing } from "lit";
import { connect } from "pwa-helpers";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import LeglModal from "../../../../../../legl-ui/modal";
import { patch, post } from "../../../../../../legl-ui/utils/fetch";
import store, {
  engageActions,
  payActions,
} from "../../../../../../redux/lawyers-admin";

class SendInitialEmailModal extends connect(store)(LeglModal) {
  static get styles() {
    return [
      super.styles,
      css`
                .reminder-checkbox {
                    font-size: 12px;
                    color: ${neutral["800"]};
                    padding-bottom: 16px;
                }
            `,
    ];
  }
  static get properties() {
    return {
      contactEmail: {
        type: String,
        // attribute: "contact-email",
      },
      enableReminders: {
        type: Boolean,
      },
    };
  }

  static get tagName() {
    return "send-initial-email-modal";
  }

  constructor() {
    super();
    this.emailRemindersEnabled = true;
  }

  get closeEventDetails() {
    return this.emailRemindersEnabled;
  }

  get modalContent() {
    return html`
            <p>
                ${
                  this.enableReminders
                    ? "In order to send automated reminders after 1, 3, 7, 14 and 30 days, you need to send the request from the Legl platform."
                    : `An email with a link to the request will be sent to your contact's email (${this.contactEmail}).`
                }
            </p>
            <p>
                ${
                  this.enableReminders
                    ? "Do you want to send it now?"
                    : "Include reminders to also send automatic emails after 1, 3, 7, 14 and 30 days?"
                }
            </p>
            ${
              !this.enableReminders
                ? html`<legl-checkbox
                      class="reminder-checkbox"
                      label="Include reminders"
                      .checked=${true}
                      @model-value-changed=${(e) => {
                        this.emailRemindersEnabled =
                          e.currentTarget.modelValue.checked;
                      }}
                      data-cy-reminders-modal-checkbox
                  ></legl-checkbox>`
                : nothing
            }
        `;
  }
}

customElements.define(SendInitialEmailModal.tagName, SendInitialEmailModal);

export const initializeSendInitialEmail = async ({
  contactEmail,
  sendEmailUrl,
  requestUrl,
  enableReminders,
  requestId,
  productType,
}) => {
  try {
    const emailRemindersEnabled = await SendInitialEmailModal.create({
      title: enableReminders ? "Switch reminders on" : "Send email",
      confirmText: "Send email",
      cancelText: "Cancel",
      contactEmail,
      enableReminders,
    });

    if (enableReminders || emailRemindersEnabled) {
      await patch(requestUrl, {
        body: JSON.stringify({
          reminders: {
            enabled: true,
          },
        }),
      });
    }

    const res = await post(sendEmailUrl);
    const request = await res.json();

    if (requestId && productType === "engage") {
      store.dispatch(engageActions.updateApplicationStatus(requestId));
    } else if (requestId && productType === "pay") {
      store.dispatch(payActions.updatePaymentStatus(requestId));
    }

    if (requestId && productType === "engage") {
      store.dispatch(engageActions.fetchApplicationDetails(request));
    } else if (requestId && productType === "pay") {
      store.dispatch(payActions.fetchPaymentDetails(request));
    }

    const adminTable = document.querySelector("legl-admin-table");
    if (adminTable) {
      adminTable.reload();
    }
  } catch (e) {    
    return e;
  }
};
