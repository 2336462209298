import { LitElement, css, html } from "lit";
import { formatDateTime } from "../../../../../../../../../legl-ui/utils/functions.js";

import { neutral } from "../../../../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../../../../../../../legl-ui/lds-typography/index.js";

export class RefundRequestSuccess extends LitElement {
  static get styles() {
    return css`
            .container {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: ${spacing.xs};
            }

            .refund-request-success-message {
              color: ${neutral["900"]};
              ${typographyPresets.body};
              margin: 0 0 ${spacing.m} 0;
            }

            .refund-info {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: ${spacing.s};
                padding: ${spacing.s};
                border: 1px solid ${neutral["200"]};
                font-size: 14px;
            }

            .capitalize {
                text-transform: capitalize;
            }

            .info-item {
                display: contents;
                color: ${neutral["900"]};
                ${typographyPresets.medium};
            }

            .info-item span:first-of-type {
                font-weight: bold;
            }

            .button-container {
                display: flex;
                justify-content: flex-end;
                margin-top: ${spacing.s};
            }
        `;
  }

  static get properties() {
    return {
      payment: { attribute: false },
      refundRequest: { attribute: false },
      onClickClose: { attribute: false },
    };
  }

  render() {
    return html`
            <div class="container">
                <p class="refund-request-success-message">This request has been created will be processed within 3-5 business days. Both you and the contact will be notified when this has been completed.</p>
                  <div class="refund-info">
                    <div class="info-item">
                        <span>Contact</span>
                        <span>
                          <div>
                            ${this.payment.first_name} ${this.payment.last_name}
                          </div>
                          <div>${this.payment.email}</div>
                        </span>
                    </div>
                    <div class="info-item">
                        <span>Refund amount</span>
                        <span
                            ><legl-currency .currency=${this.payment.currency}
                                >${this.refundRequest.amount}</legl-currency
                            ></span
                        >
                    </div>
                    <div class="info-item">
                        <span>Refund type</span>
                        <span class="capitalize"
                            >${this.refundRequest.refund_type}</span
                        >
                    </div>
                    <div class="info-item">
                        <span>Bank account</span>
                        <span class="capitalize"
                            >${this.refundRequest.bank_account.name}</span
                        >
                    </div>
                    <div class="info-item">
                      <span>Date request created</span>
                      <span
                      >${formatDateTime(this.refundRequest.created_at)}</span
                      >
                    </div>
                    <div class="info-item">
                      <span>Request created by</span
                      ><span>${this.refundRequest.requested_by}</span>
                    </div>
                    <div class="info-item">
                        <span>Reason</span>
                        <span>${this.refundRequest.reason}</span>
                    </div>
                </div>
                <div class="button-container">
                  <lds-button
                    colour="primary"
                    @click=${this.onClickClose}
                    >Close</lds-button>
                </div>
            </div>
        `;
  }
}

customElements.define("refund-request-success", RefundRequestSuccess);
