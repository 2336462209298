export const WaffleFlags = {
  SIGNATURE_REQUEST_SWITCH: "signature_request",
  ENABLE_LEGL_ASSIST_CDD: "enable_legl_assist_cdd",
  CONFLICT_CHECKS_FLAG: "conflict_checks",
  VUE_PAY_CHECKOUT_FLAG: "vue-pay-checkout",
  STANDARD_CDD_NO_ID: "standard_cdd_no_id",
  USE_NEW_PAYMENT_METHOD_SERVICE: "use-new-payment-method-service",
  ADYEN_ALLOWED: "adyen-allowed",
  ADYEN_AUTO_ADDRESS: "adyen-auto-address",
};
