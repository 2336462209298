import { LitElement, css, html } from "lit";
import { map } from "lit/directives/map.js";
import { neutral, primary } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import { get } from "../../../../../../legl-ui/utils/fetch";
import { formatDate } from "../../../../../../legl-ui/utils/functions.js";
import { createCancellationModal } from "../../pages/pay/components/modals/payment-plan-cancellation/payment-plan-cancellation-modal";

export class LeglPaymentPlanReview extends LitElement {
  static get styles() {
    return css`
            .heading-actions-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: ${spacing.m};
                border-bottom: 1px solid ${neutral["300"]};
                margin-bottom: ${spacing.l};
            }

            legl-card:nth-child(2) {
                margin-bottom: ${spacing.m};
            }

            .plan-details-row {
                display: flex;
                flex-wrap: wrap;
                gap: ${spacing.m};
            }

            .plan-details-row dt {
                ${typographyPresets.smallBold};
                color: ${neutral["800"]};
            }

            .plan-details-row dd {
                ${typographyPresets.small};
                color: ${neutral["600"]};
                margin: 0;
            }

            table {
                width: 100%;
                border-spacing: ${spacing.s};
            }

            table thead {
                text-align: left;
            }

            th {
                ${typographyPresets.bodyBold};
                color: ${neutral["800"]};
            }

            td {
                ${typographyPresets.body};
                color: ${neutral["600"]};
            }

            td a {
                color: ${primary["500"]};
            }

            .invoice-link {
                color: ${primary["500"]};
                text-decoration: none;
                align-items: center;
                display: flex;
                gap: ${spacing.xs};
            }

            .invoice-link span {
                text-decoration: underline;
            }

            legl-action-bar {
                --legl-action-bar-min-width: 0;
            }
        `;
  }

  static get properties() {
    return {
      paymentPlan: { state: true },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchPaymentPlanInfo();
  }

  get uid() {
    return this.params.get("uid");
  }

  async fetchPaymentPlanInfo() {
    const res = await get(`/api/payment_schedules/${this.uid}/`);
    this.paymentPlan = await res.json();
  }

  transformStatus(status) {
    if (status == "Created") {
      return "Due";
    }
    return status;
  }

  openCancelLinksModal() {
    createCancellationModal(this.uid, (plan) => {
      this.paymentPlan = plan;
      window.dispatchEvent(new CustomEvent("reload-payments-table"));
    });
  }

  getActivityText(event) {
    switch (event.type) {
      case "payment_schedule_created":
        return "Payment plan was created";
      case "advance_warning_sent":
        return event.comment;
      case "payment_schedule_cancelled":
        const prefix = `${event.triggered_by.name} <strong class="bold">cancelled</strong> this payment plan.`;
        let suffix = "";
        if (event.comment) {
          suffix = ` The reason was: <strong class="bold">${event.comment}</strong>.`;
        }
        return prefix + suffix;
      default:
        return "";
    }
  }

  get sortedHistory() {
    return this.paymentPlan?.events
      .sort((a, b) => {
        if (a.created_at > b.created_at) {
          return -1;
        }

        if (b.created_at > a.created_at) {
          return 1;
        }
        return 0;
      })
      .map((event) => {
        return html`
                    <legl-activity-log
                        data-cy-payment-schedule-event
                        user=${
                          event.triggered_by ? event.triggered_by.name : "Legl"
                        }
                        .activityText=${this.getActivityText(event)}
                        date=${event.created_at}
                    ></legl-activity-log>
                `;
      });
  }

  render() {
    return html`
            <div class="heading-actions-row">
                <legl-request-status status="${this.paymentPlan?.status}">
                    ${this.paymentPlan?.status}
                </legl-request-status>
                <legl-action-bar>
                    <button
                        .disabled=${
                          this.paymentPlan?.status === "Completed" ||
                          this.paymentPlan?.status === "Cancelled"
                        }
                        slot="secondary"
                        @click=${this.openCancelLinksModal}
                    >
                        Cancel
                    </button>
                </legl-action-bar>
            </div>
            <legl-card data-cy-payment-schedule-details>
                <h3 slot="title" variant="corner">PLAN DETAILS</h3>
                <dl class="plan-details-row">
                    <div>
                        <dt class="title">Total amount</dt>
                        <dd>
                            <legl-currency
                                data-cy-payment-schedule-total-amount
                                value=${this.paymentPlan?.total_amount}
                            ></legl-currency>
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Number of instalments</dt>
                        <dd data-cy-payment-schedule-number-of-instalments>
                            ${this.paymentPlan?.number_of_instalments}
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Monthly instalments</dt>
                        <dd>
                            <legl-currency
                                data-cy-payment-schedule-instalment-amount
                                value=${this.paymentPlan?.instalment_amount}
                            ></legl-currency>
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Remainder instalment</dt>
                        <dd data-cy-payment-schedule-remainder-instalment>
                            ${
                              Number.parseFloat(
                                this.paymentPlan?.remainder_instalment_amount,
                              )
                                ? html`<legl-currency
                                      .value=${this.paymentPlan?.remainder_instalment_amount}
                                  ></legl-currency>`
                                : "-"
                            }
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Creation date</dt>
                        <dd data-cy-payment-schedule-creation-date>
                            ${formatDate(this.paymentPlan?.created_at, {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })}
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Balance remaining</dt>
                        <dd>
                            <legl-currency
                                data-cy-payment-schedule-balance-remaining
                                value=${this.paymentPlan?.balance_remaining}
                            ></legl-currency>
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Instalments remaining</dt>
                        <dd data-cy-payment-schedule-remaining-instalments>
                            ${this.paymentPlan?.remaining_instalments}
                        </dd>
                    </div>
                    <div>
                        <dt class="title">First instalment date</dt>
                        <dd data-cy-payment-schedule-first-instalment-date>
                            ${formatDate(this.paymentPlan?.start_date, {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })}
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Next instalment date</dt>
                        <dd data-cy-payment-schedule-next-instalment-date>
                            ${formatDate(this.paymentPlan?.next_due_at, {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })}
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Contact</dt>
                        <dd>
                            <legl-contact-card
                                .contact="${
                                  this.paymentPlan?.initial_payment.contact
                                }"
                            ></legl-contact-card>
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Plan reference</dt>
                        <dd data-cy-payment-schedule-reference>
                            ${this.paymentPlan?.reference}
                        </dd>
                    </div>
                    <div>
                        <dt class="title">Invoice attachment</dt>
                        <dd>
                            ${
                              this.paymentPlan?.initial_payment.has_document
                                ? html`<a
                                      class="invoice-link"
                                      href=${this.paymentPlan?.initial_payment.document_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                  >
                                      <lds-icon
                                          name="lds-icon-VisibilityOn"
                                      ></lds-icon>
                                      <span>View</span>
                                  </a>`
                                : "-"
                            }
                        </dd>
                    </div>
                </dl>
            </legl-card>
            <legl-card>
                <h3 slot="title" variant="corner">PAYMENT SCHEDULE</h3>
                <table data-cy-payment-schedule-table>
                    <thead>
                        <tr>
                            <th>Instalment</th>
                            <th>Due date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${map(
                          this.paymentPlan?.instalment_schedule,
                          (instalment, index) =>
                            html`
                                    <tr data-cy-payment-schedule-table-row>
                                        <td>${index + 1}</td>
                                        <td
                                            data-cy-payment-schedule-table-row-date
                                        >
                                            ${formatDate(instalment.due_date, {
                                              month: "short",
                                              day: "numeric",
                                              year: "numeric",
                                            })}
                                        </td>
                                        <td>
                                            <legl-currency
                                                data-cy-payment-schedule-table-row-amount
                                                value=${instalment.amount}
                                            ></legl-currency>
                                        </td>
                                        <td>
                                            <legl-request-status
                                                data-cy-payment-schedule-table-row-status
                                                status="${this.transformStatus(
                                                  instalment.status,
                                                )}"
                                            >
                                                ${this.transformStatus(
                                                  instalment.status,
                                                )}
                                            </legl-request-status>
                                        </td>
                                        <td
                                            data-cy-payment-schedule-table-row-actions
                                        >
                                            ${
                                              instalment.id
                                                ? html`<a
                                                      href="#/drawer/pay-review/?appId=${instalment.id}"
                                                      >View</a
                                                  >`
                                                : "-"
                                            }
                                        </td>
                                    </tr>
                                `,
                        )}
                    </tbody>
                </table>
            </legl-card>

            <div data-cy-payment-schedule-history id="plan-history">
                <h3>Payment Plan history</h3>
                ${this.sortedHistory}
            </div>
        `;
  }
}

customElements.define("legl-payment-plan-review", LeglPaymentPlanReview);
