import { LitElement, css, html, nothing } from "lit";
import "../../../../../../legl-ui/activity-log";
import { neutral, primary } from "../../../../../../legl-ui/lds-colours";
import { LdsModal } from "../../../../../../legl-ui/lds-modal";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import { get, post } from "../../../../../../legl-ui/utils/fetch.js";
import {
  formatDate,
  formatDateTime,
} from "../../../../../../legl-ui/utils/functions.js";
import "../../../../../lawyers-app/static-src/js/components/modals/assign-monitoring-reviewer-modal/assign-monitoring-reviewer-modal.js";
import { userConfig } from "../../../../../lawyers-app/static-src/js/config/user-config";
import "../mark-review/mark-review-ui.js";
import "./alert-pdf-panel.js";

class MonitoringUpdate extends LitElement {
  static get properties() {
    return {
      alert: { attribute: false },
      params: { attribute: false, state: true },
      reviewUrl: { attribute: false, state: true },
      businessInfo: { attribute: false, state: true },
      contactInfo: { attribute: false, state: true },
    };
  }

  constructor() {
    super();
    this.alert = null;
    this.params = new URLSearchParams();
    this.reviewUrl = null;
    this.businessInfo = null;
    this.contactInfo = null;
  }

  static get styles() {
    return css`
            :host {
                display: block;
                ${typographyPresets.body};
            }

            a {
                color: ${neutral["800"]};
            }

            .o-alert__content-details {
                width: 100%;
            }

            .o-alert__contact-alert-details {
                display: grid;
                grid-template-columns: 280px 1fr;
                grid-gap: ${spacing.s};
                padding-top: ${spacing.m};
                margin: 0;
                align-items: top;
            }

            .o-alert__contact-alert-details:not(.reviewer-ui) {
                margin-bottom: ${spacing.s};
            }

            .o-alert__meta-label {
                font-weight: 700;
            }

            .o-alert__title {
                font-size: 20px;
                margin-bottom: 32px;
                color: ${neutral["800"]};
            }

            .o-alert__pdf {
                margin-top: 40px;
                display: block;
            }

            .reviewer-button {
                height: auto;
            }

            .reviewer-wrapper {
                display: flex;
                align-items: center;
                gap: ${spacing.xs};
            }

            .associated-businesses {
                margin: 0;
            }

            .associated-businesses dd {
                margin-left: 0;
                ${typographyPresets.small};
            }

            .associated-businesses dt:not(:first-of-type) {
                margin-top: ${spacing.s};
            }

            .view-report-link {
                color: ${primary["500"]};
            }

            .reviewer-ui {
                border-top: 1px solid ${neutral["200"]};
            }
            p {
              margin: 0;
            }
        `;
  }

  firstUpdated() {
    this.fetchAlert();
    this.getEntityInfo();
  }

  get alertId() {
    return this.params.get("alertId");
  }

  get businessId() {
    return this.params.get("businessId");
  }

  get contactId() {
    return this.params.get("contactId");
  }

  async fetchAlert() {
    let res;
    if (this.businessId) {
      res = await get(`/api/business_monitoring_alerts/${this.alertId}/`);
    } else {
      res = await get(`/api/monitoring_alerts/${this.alertId}/`);
    }
    if (res.ok) {
      const json = await res.json();
      this.alert = json;
      this.reviewUrl = json.review_url;
    } else {
      LdsToast.showError({
        text: "Unable to load alert. Please try again later.",
      });
    }
  }

  get reducedAssociatedBusinesses() {
    return this.contactInfo.businesses?.reduce(
      (accumulator, associatedBusiness) => {
        let type = associatedBusiness.type;
        if (associatedBusiness.type === "Shareholder") {
          type = `${associatedBusiness.type} ${associatedBusiness.shareholder_percentage}%`;
        }

        const reducedAssociatedBusiness = accumulator.find(
          (reducedAssociatedBusiness) =>
            reducedAssociatedBusiness.business_id ===
            associatedBusiness.business_id,
        );
        if (reducedAssociatedBusiness) {
          reducedAssociatedBusiness.type = `${reducedAssociatedBusiness.type}, ${type}`;
        } else {
          accumulator.push({
            type,
            name: associatedBusiness.name,
            business_id: associatedBusiness.business_id,
          });
        }
        return accumulator;
      },
      [],
    );
  }

  async getEntityInfo() {
    if (this.businessId) {
      const business = await get(`/api/businesses/${this.businessId}/`);
      if (business.ok) {
        this.businessInfo = await business.json();
      } else {
        LdsToast.showError({ text: "business info cannot be loaded" });
        throw new Error("");
      }
    } else {
      const contact = await get(`/api/contacts/${this.contactId}`);
      if (contact.ok) {
        this.contactInfo = await contact.json();
      } else {
        LdsToast.showError({ text: "contact info cannot be loaded" });
        throw new Error("");
      }
    }
  }

  reloadTables = () => {
    if (window.location.pathname.includes("monitoring")) {
      window.dispatchEvent(
        new CustomEvent("reload-monitoring-dashboard-table"),
      );
    } else {
      window.dispatchEvent(
        new CustomEvent("reload-business-monitoring-alerts-table"),
      );
    }
  };

  markReviewAction = async (comment) => {
    const res = await post(`${this.reviewUrl}`, {
      body: JSON.stringify({
        comment,
      }),
    });
    if (res.ok) {
      const reviewedAlert = await res.json();
      this.alert = reviewedAlert;
      LdsToast.showSuccess({
        text: "Marked as reviewed.",
        autoClose: true,
      });
      this.reloadTables();
    } else {
      LdsToast.showError({ text: "Error saving, please try again" });
      throw new Error("");
    }
  };

  getEventIndividual(event) {
    return event.triggered_by ? event.triggered_by.name : "Legl";
  }

  getActivityText(event) {
    switch (event.type) {
      case "alert_created":
        return "Update was reported";
      case "alert_reviewed":
        return `${event?.triggered_by?.name} reviewed this request`;
      case "alert_reviewer_assigned":
        return `${event?.triggered_by?.name} assigned ${event?.associated_individual?.name} as the reviewer`;
      default:
        return "";
    }
  }

  get sortedHistory() {
    return this.alert.events
      .map((x) => ({ ...x }))
      .sort((a, b) => {
        if (a.created_at > b.created_at) {
          return -1;
        }

        if (b.created_at > a.created_at) {
          return 1;
        }
        return 0;
      })
      .map((event) => {
        return html`
                    <legl-activity-log
                        data-cy-review-activity-log
                        comment=${event.comment}
                        user=${this.getEventIndividual(event)}
                        .activityText=${this.getActivityText(event)}
                        date=${event.created_at}
                    ></legl-activity-log>
                `;
      });
  }

  openAssignReviewerModal = () => {
    LdsModal.create({
      title: "Assign reviewer",
      slotComponent: "assign-monitoring-reviewer",
      slotComponentProperties: {
        monitoringAlertId: this.alertId,
        reviewersOptions: userConfig.engageReviewers,
        assignedReviewer: this.alert.assigned_reviewer?.pk,
      },
    });
  };

  get assignedReviewerUI() {
    return html`<dt class="o-alert__meta-label">
                Monitoring update reviewer
            </dt>
            <dd class="o-alert__meta-datum">
                ${
                  this.alert.assigned_reviewer
                    ? html`<div class="reviewer-wrapper">
                          <span>${this.alert.assigned_reviewer?.name}</span>
                          <div>
                              <lds-button
                                  class="reviewer-button"
                                  colour="primary"
                                  variant="outlined"
                                  .small=${true}
                                  @click=${this.openAssignReviewerModal}
                                  >Change</lds-button
                              >
                          </div>
                      </div>`
                    : html`<div>
                          <lds-button
                              class="reviewer-button"
                              colour="primary"
                              variant="outlined"
                              .small=${true}
                              @click=${this.openAssignReviewerModal}
                          >
                              Assign reviewer
                          </lds-button>
                      </div>`
                }
            </dd>`;
  }

  get reviewerUI() {
    return html`<dt class="o-alert__meta-label">Reviewer</dt>
            <dd class="o-alert__meta-datum">
                ${
                  this.alert.events.find(
                    (event) => event.type === "alert_reviewed",
                  ).triggered_by?.name || "-"
                }
            </dd>`;
  }

  get businessDetails() {
    return html`
            <dl class="o-alert__contact-alert-details">
                <dt class="o-alert__meta-label">Company name</dt>
                <dd class="o-alert__meta-datum">${this.businessInfo.name}</dd>
                <dt class="o-alert__meta-label">Company number</dt>
                <dd class="o-alert__meta-datum">
                    ${this.businessInfo.registration_number || "-"}
                </dd>
                <dt class="o-alert__meta-label">Address</dt>
                <dd class="o-alert__meta-datum">
                    ${this.businessInfo.simple_address || "-"}
                </dd>
                <dt class="o-alert__meta-label">Search reference</dt>
                <dd class="o-alert__meta-datum">
                    ${this.alert.search_reference || "-"}
                </dd>
                <dt class="o-alert__meta-label">Updated at</dt>
                <dd class="o-alert__meta-datum">
                    ${
                      formatDateTime(this.alert.created_at, {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }) || "-"
                    }
                </dd>
                <dt class="o-alert__meta-label">Initial report</dt>
                <dd>
                    <div>
                        ${
                          this.alert.initial_report_reviewed_by
                            ? `Reviewed by ${this.alert?.initial_report_reviewed_by?.name}`
                            : `Assigned to ${this.alert?.initial_report_assigned_to?.name}`
                        }
                    </div>
                    <a
                        class="view-report-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href=${`/businesses/${this.businessId}/sanctions_report/${this.alert?.engage_request_id}`}
                        >View report</a
                    >
                </dd>
            </dl>
            <dl class="o-alert__contact-alert-details reviewer-ui">
                ${
                  this.alert.status === "Reviewed"
                    ? this.reviewerUI
                    : this.assignedReviewerUI
                }
            </dl>
        `;
  }

  get contactDetails() {
    return html`
            <dl class="o-alert__contact-alert-details">
                <dt class="o-alert__meta-label">Full name</dt>
                <dd class="o-alert__meta-datum">${this.contactInfo.name}</dd>
                <dt class="o-alert__meta-label">Date of birth</dt>
                <dd class="o-alert__meta-datum">
                    ${formatDate(this.contactInfo.date_of_birth, {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                </dd>
                <dt class="o-alert__meta-label">Address</dt>
                <dd class="o-alert__meta-datum">
                    ${this.contactInfo.address_line_1 || "-"}
                </dd>
                <dt class="o-alert__meta-label">Search reference</dt>
                <dd class="o-alert__meta-datum">
                    ${this.alert.search_reference || "-"}
                </dd>
                <dt class="o-alert__meta-label">Updated at</dt>
                <dd class="o-alert__meta-datum">
                    ${
                      formatDateTime(this.alert.created_at, {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }) || "-"
                    }
                </dd>
                <dt class="o-alert__meta-label">Associated businesses</dt>
                <dd>
                    ${
                      this.reducedAssociatedBusinesses?.length
                        ? html`
                              <dl class="associated-businesses">
                                  ${this.reducedAssociatedBusinesses?.map(
                                    (associatedBusiness) => html`
                                          <dt>${associatedBusiness.name}</dt>
                                          <dd>${associatedBusiness.type}</dd>
                                      `,
                                  )}
                              </dl>
                          `
                        : html`
                              <p class="no-associated-businesses-message">
                                  This contact is not currently associated<br />with
                                  any businesses
                              </p>
                          `
                    }
                </dd>
                <dt class="o-alert__meta-label">Initial report</dt>
                <dd class="o-alert__meta-datum">
                    <div>
                        ${
                          this.alert.initial_report_reviewed_by
                            ? `Reviewed by ${this.alert?.initial_report_reviewed_by?.name}`
                            : `Assigned to ${this.alert?.initial_report_assigned_to?.name}`
                        }
                    </div>
                    <a
                        class="view-report-link"
                        href=${`/engage-review/?appId=${this.alert?.engage_request_id}`}
                        >View report</a
                    >
                </dd>
            </dl>
            <dl class="o-alert__contact-alert-details reviewer-ui">
                ${
                  this.alert.status === "Reviewed"
                    ? this.reviewerUI
                    : this.assignedReviewerUI
                }
            </dl>
        `;
  }

  render() {
    if (!this.alert || (!this.businessInfo && !this.contactInfo)) {
      return nothing;
    }
    return html`
            <div class="o-alert" data-cy="monitoring-alert-drawer">
                <div class="o-alert__status">
                    <legl-request-status status=${this.alert.status}
                        >${this.alert.status}</legl-request-status
                    >
                </div>
                <h2 class="o-alert__title" data-cy="monitoring-alert-title">
                    ${
                      this.businessInfo
                        ? "Sanctions monitoring update"
                        : "Watchlist monitoring update"
                    }
                </h2>
                <legl-card class="o-alert__alert-details-card">
                    <h3 slot="title" variant="corner">MONITORING DETAILS</h3>
                    <div class="o-alert__content-details">
                        ${
                          this.businessInfo
                            ? this.businessDetails
                            : this.contactDetails
                        }
                    </div>
                </legl-card>
                <legl-ongoing-monitoring-pdf-panel
                    class="o-alert__pdf"
                    .pdfUrl=${this.alert?.report_url}
                ></legl-ongoing-monitoring-pdf-panel>
                ${
                  this.alert?.status === "Ready for review"
                    ? html`<mark-review-UI
                          data-cy="mark-review"
                          .action=${this.markReviewAction}
                      ></mark-review-UI>`
                    : nothing
                }
                <div id="update-alert-history">
                    <h2 class="o-alert__title">Update alert history</h2>
                    ${this.sortedHistory}
                </div>
            </div>
        `;
  }
}

customElements.define("legl-monitoring-update", MonitoringUpdate);
