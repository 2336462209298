import { LionButtonSubmit } from "@lion/button";
import { css, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";
import { LdsButtonMixin } from "./lds-button-mixin.js";
import { LdsButtonStylesMixin } from "./lds-button-styles-mixin.js";

import "../../../legl-ui/lds-icon";

export class LdsButton extends LdsButtonStylesMixin(
  LdsButtonMixin(LionButtonSubmit),
) {
  static get properties() {
    return {
      icon: {
        type: String,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
          :host {
              ${typographyPresets.bodySemiBold};
              padding: calc(${spacing.xxs} + ${spacing.xs}) ${spacing.m};
              display: inline-flex;
              height: 48px;
              box-sizing: border-box;
          }

          lds-icon {
              padding-right: ${spacing.xs};
          }

          .is-loading .button-content,
          .is-loading lds-icon {
              visibility: hidden;
          }

          lds-spinner {
              position: absolute;
          }

          .button-content-container {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
          }
      `,
    ];
  }

  constructor() {
    super();
    this.isLoading = false;
  }

  _beforeTemplate() {
    if (this.icon) {
      return html`<lds-icon
                part="icon"
                ?small=${this.small}
                name="${this.icon}"
            ></lds-icon>`;
    }

    return "";
  }

  _afterTemplate() {
    return "";
  }

  render() {
    return html`<div
            class=${classMap({
              "button-content-container": true,
              "is-loading": this.isLoading,
            })}
        >
            ${this.isLoading ? this.loadingIcon : nothing}
            ${this._beforeTemplate()} ${super.render()} ${this._afterTemplate()}
        </div>`;
  }
}
if (!customElements.get("lds-button")) {
  customElements.define("lds-button", LdsButton);
}
