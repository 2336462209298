import { get } from "../../../../../legl-ui/utils/fetch";

export class UserConfig {
  constructor() {
    this._data = {};
    this.isLoading = false;
    this.hasLoaded = false;
  }

  async fetchConfig() {
    try {
      const res = await get("/json/user_config/");
      if (!res.ok) {
        const text = await res.text();
        throw new Error(`Error ${res.status}: ${text}`);
      }
      this._data = await res.json();
      this.hasLoaded = true;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  get navigation() {
    return this._data.navigation;
  }

  get userId() {
    return this._data.user?.id;
  }

  get userEmail() {
    return this._data.user.email;
  }

  get engageReviewers() {
    return this._data?.engage?.reviewers || [];
  }

  get permissions() {
    return this._data?.user?.permissions;
  }

  get meta_data() {
    return this._data?.user?.meta_data;
  }

  get isCurrentUserAdmin() {
    return this.permissions?.admin;
  }

  get isConsultant() {
    return this.permissions?.consultant;
  }

  get hasRefundPermissions() {
    return this.permissions?.refunds;
  }

  get engageHelpLink() {
    return this._data?.engage?.helpLink;
  }

  get maximumPhonePayment() {
    return this._data?.company?.paymentSettings?.maximumPhonePayment;
  }

  get companyLocale() {
    return this._data?.company?.locale;
  }

  get companyCurrency() {
    return this._data?.company?.currency;
  }

  get paymentFirmReferenceFieldConfigs() {
    return [
      {
        ...this._data?.company?.paymentSettings?.firmReferenceOne,
        name: "firm_reference_one",
      },
      {
        ...this._data?.company?.paymentSettings?.firmReferenceTwo,
        name: "firm_reference_two",
      },
    ].filter((config) => config && config.isVisible);
  }

  get companyId() {
    return this._data?.company?.id;
  }

  get companyName() {
    return this._data?.company?.name;
  }

  get companySlug() {
    return this._data?.company?.slug;
  }

  get companyProducts() {
    return {
      paymentsEnabled: this._data?.company?.payments_enabled,
      phonePaymentsEnabled:
        this._data?.company?.phone_payments_enabled &&
        this._data?.company?.payments_enabled,
      engageEnabled: this._data?.company?.engage_enabled,
      sourceOfFundsAiEnabled: this._data?.company?.source_of_funds_ai_enabled,
      ongoingMonitoringEnabled: this._data?.company?.ongoing_monitoring_enabled,
      reviewCommentPdf: this._data?.company?.review_comment_pdf,
      businessOnboardingAutomation:
        this._data?.company?.business_onboarding_automation,
    };
  }

  get stripeKey() {
    return this._data?.stripe_publishable_key;
  }

  get hellosign_client_id() {
    return this._data?.hellosign_client_id;
  }

  get true_layer_client_id() {
    return this._data?.true_layer_client_id;
  }

  getModalConfig(configKey) {
    const config = this._data.globalModals || {};
    return config[configKey] || {};
  }

  get clientReferenceRequired() {
    return Boolean(this._data?.company?.client_reference_validation?.required);
  }
}

export const userConfig = new UserConfig();
