import { LitElement, css, html } from "lit";

export class LdsSpinner extends LitElement {
  static get styles() {
    return css`
            :host {
                display: flex;
            }

            .progressIcon-root {
                display: inline-block;
            }

            .progressIcon-indeterminate {
                animation: progressIcon-keyframes-circular-rotate 1.4s linear
                    infinite;
            }

            .progressIcon-svg {
                display: block;
            }

            .progressIcon-circle {
                stroke: currentColor;
            }

            .progressIcon-circleIndeterminate {
                animation: progressIcon-keyframes-circular-dash 1.4s ease-in-out
                    infinite;
            }

            @-webkit-keyframes progressIcon-keyframes-circular-rotate {
                0% {
                    transform-origin: 50% 50%;
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            @-webkit-keyframes progressIcon-keyframes-circular-dash {
                0% {
                    stroke-dasharray: 1px, 80px;
                    stroke-dashoffset: 0px;
                }
                50% {
                    stroke-dasharray: 40px, 80px;
                    stroke-dashoffset: -15px;
                }
                100% {
                    stroke-dasharray: 40px, 80px;
                    stroke-dashoffset: -50px;
                }
            }
        `;
  }

  render() {
    return html`
            <div
                class="progressIcon-root progressIcon-indeterminate"
                role="progressbar"
                style="width: 24px; height: 24px;"
            >
                <svg class="progressIcon-svg" viewbox="0 0 24 24">
                    <circle
                        class="progressIcon-circle progressIcon-circleIndeterminate"
                        cx="12"
                        cy="12"
                        r="8"
                        fill="none"
                        stroke-width="2"
                    ></circle>
                </svg>
            </div>
        `;
  }
}
if (!customElements.get("lds-spinner")) {
  customElements.define("lds-spinner", LdsSpinner);
}
