import { LitElement, css, html, nothing } from "lit";
import LeglFormValidationMixin from "../../../../../../../../../../static-src/js/mixins/LeglFormValidationMixin";
import {
  LeglMaxAmount,
  LeglMinAmount,
  LeglRequired,
} from "../../../../../../../../../legl-ui/lds-input";
import { spacing } from "../../../../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../../../../legl-ui/lds-toast";
import { get, post } from "../../../../../../../../../legl-ui/utils/fetch.js";
import "./refund-request-success";

export class RefundModal extends LeglFormValidationMixin(LitElement) {
  static get styles() {
    return css`
            .refund-modal {
                padding: 0 ${spacing.m} ${spacing.m} ${spacing.m};
            }
            .refund-alert-message {
              margin: ${spacing.m} 0;
            }
            .refund-modal-button-container {
                display: flex;
                justify-content: flex-end;
                gap: ${spacing.s};
            }
            .refund-amount {
                width: 240px;
            }
        `;
  }

  static get properties() {
    return {
      refundRequest: { attribute: false },
      payment: { attribute: false },
      showOther: { state: true },
      showPartialInput: { state: true },
      isSubmitting: { state: true },
      refundSubmitted: { state: true },
      bankAccounts: { state: true },
    };
  }

  constructor() {
    super();
    this.isSubmitting = false;
    this.showOther = false;
    this.showPartialInput = false;
    this.refundSubmitted = false;
    this.bankAccounts = [];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.fetchBankAccounts();
  }

  async fetchBankAccounts() {
    try {
      const res = await get(`/api/bank_accounts/`);
      if (!res.ok) {
        throw new Error(res.error);
      }
      this.bankAccounts = await res.json();
      if (this.bankAccounts.length === 1) {
        this.refundRequest.bank_account_reference = this.bankAccounts[0].reference
      }
    } catch {
      toastService.showError("Error retrieving bank accounts");
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.validate();
    if (this.isValid) {
      this.isSubmitting = true;

      const response = await post("/refund/", {
        body: JSON.stringify({
          reference: this.payment.reference,
          refund_type: this.refundRequest.refund_type,
          reason: this.refundRequest.reason,
          amount: this.refundRequest.amount,
          bank_account_reference: this.refundRequest.bank_account_reference,
        }),
      });
      if (response.status === 201) {
        const JSONResponse = await response.json();
        
        this.refundRequest.bank_account = this.bankAccount;
        this.refundRequest.created_at = JSONResponse.created_at;
        this.refundRequest.requested_by = JSONResponse.requested_by;
        this.refundSubmitted = true;
        this.dispatchReloadEvents();
      } else {
        if (response.status === 403) {
          LdsToast.showError({ text: "You do not have permission to refund payments" });
        } else if (response.status === 500) {
          LdsToast.showError({ text: "Something went wrong. Please try again later." });
        } else {
          const JSONResponse = await response.json();
          LdsToast.showError({ text: JSONResponse.error });
        }
      }
    }
    this.isSubmitting = false;
  }

  dispatchReloadEvents() {
    window.dispatchEvent(
      new CustomEvent("reload-payments-table", {
        bubbles: true,
        composed: true,
      }),
    );
    window.dispatchEvent(new CustomEvent("reload-payment-drawer"));
  }

  onClickClose() {
    this.parentElement.onClickClose();
  }

  boundClickClose = this.onClickClose.bind(this);

  get displayCurrency() {
    if (this.payment.currency === "GBP") {
      return "£";
    } else if (this.payment.currency === "EUR") {
      return "€";
    }
  }

  get bankAccount() {
    return this.bankAccounts.find(
      (bankAccount) =>
        bankAccount.reference === this.refundRequest.bank_account_reference,
    );
  }

  get outstandingRefundBalance() {
    // Note the GET returns pennies but we should also post in pennies and not decimals
    return parseFloat((this.refundRequest.outstanding_refund_balance / 100).toFixed(2));
  }

  // List of refund reasons to be displayed in the radio group
  get refundReasonChoices() {
    return [
      "Client is no longer instructing",
      "We cannot accept funds from the client",
      "Returning unused funds",
      "Duplicate payment",
      "Client accidentally paid too much",
      "Other",
    ];
  }

  render() {
    return html`
            <div class="refund-modal">
                ${
                  this.refundSubmitted
                    ? html`<refund-request-success
                          .payment=${this.payment}
                          .refundRequest=${this.refundRequest}
                          .onClickClose=${this.boundClickClose}
                    ></refund-request-success>`
                    : html`<form @submit=${this.handleSubmit}>
                          <lds-radio-group
                              name="amount"
                              data-testid="refund-type-radio-group"
                              class="source-input"
                              label="How much would you like to refund?"
                              .validators=${[new LeglRequired()]}
                              @model-value-changed="${(e) => {
                                const { modelValue } = e.target;

                                this.showPartialInput =
                                  modelValue === "partial";

                                this.refundRequest.amount =
                                  modelValue === "full"
                                    ? this.outstandingRefundBalance
                                    : this.refundRequest.amount;

                                this.refundRequest.refund_type = modelValue;
                              }}"
                          >
                              <lds-radio
                                  label="Full refund (${this.displayCurrency}${this.outstandingRefundBalance})"
                                  .choiceValue=${"full"}
                              ></lds-radio>
                              <lds-radio
                                  label="Partial refund"
                                  .choiceValue=${"partial"}
                              ></lds-radio>
                          </lds-radio-group>
                          ${
                            this.showPartialInput
                              ? html`<lds-input-amount
                                    label="Amount to be refunded:"
                                    class="source-input"
                                    type="number"
                                    @model-value-changed=${(e) => {
                                      this.refundRequest.amount =
                                        e.target.modelValue;
                                    }}
                                    .currencyCode=${this.payment.currency}
                                    .validators=${[
                                      new LeglRequired(),
                                      new LeglMinAmount(0.01, {
                                        currency: this.currency,
                                        locale: "en-GB",
                                      }),
                                      new LeglMaxAmount(this.payment.amount, {
                                        currency: this.currency,
                                        locale: "en-GB",
                                      }),
                                    ]}
                                >
                                </lds-input-amount>`
                              : nothing
                          }
                          <br />
                          <lds-radio-group
                              data-testid="refund-reason-radio-group"
                              name="refund_reason"
                              class="source-input"
                              .validators=${[new LeglRequired()]}
                              label="Reason for refund:"
                              @model-value-changed=${(e) => {
                                const { modelValue } = e.target;

                                this.showOther = modelValue === "Other";
                                this.refundRequest.reason = modelValue;
                              }}
                          >
                              ${this.refundReasonChoices.map(
                                (reason) => html`<lds-radio
                                      label=${reason}
                                      .choiceValue=${reason}
                                  ></lds-radio>`,
                              )}
                          </lds-radio-group>
                          ${
                            this.showOther
                              ? html`<lds-textarea
                                    label="Other (please specify)"
                                    class="source-input"
                                    .validators=${[new LeglRequired()]}
                                    maxlength="200"
                                    @model-value-changed=${(e) => {
                                      this.refundRequest.reason =
                                        "Other - " + e.target.modelValue;
                                    }}
                                ></lds-textarea>`
                              : nothing
                          }
                          <br />
                          <div>
                            <lds-select
                                class="source-input"
                                data-testid="refund-bank-account-selection"
                                label="Please select a bank account"
                                .validators=${[new LeglRequired()]}
                                .modelValue=${this.refundRequest.bank_account_reference}
                                @model-value-changed=${(e) =>
                                  (this.refundRequest.bank_account_reference =
                                    e.target.modelValue)}
                            >
                                <select slot="input">
                                    ${
                                      this.bankAccounts.length > 1 ?
                                        html`<option value="">
                                         Please select a bank account
                                        </option>` : nothing }

                                    ${this.bankAccounts.map((account) => {
                                      return html` <option value=${account.reference}>
                                            ${account.name}
                                        </option>`;
                                    })
                                    }
                                </select>
                            </lds-select>
                          </div>
                          <br />
                          <div class="refund-alert-message">
                            <lds-alert type="info" title="Once requested, we will start processing this refund immediately" message="Please ensure the refund amount is ready to be transferred from your account."></lds-alert>
                          </div>
                          <div class="refund-modal-button-container">
                              <lds-button
                                  colour="neutral"
                                  variant="outlined"
                                  @click=${this.onClickClose}
                                  ?disabled=${this.isSubmitting}
                                  >Cancel</lds-button
                              >
                              <lds-button
                                  colour="primary"
                                  data-testid="create-refund-request-button"
                                  ?disabled=${this.isSubmitting}
                                  .isLoading=${this.isSubmitting}
                                  >Create refund request</lds-button
                              >
                          </div>
                      </form> `
                }
            </div>
        `;
  }
}

customElements.define("legl-new-refund-modal", RefundModal);
